import roomSlice from "../../../redux/features/room/room.slice";
import { Room, Wall } from "../components/Floorplan";

const colors: string[] = [
  "#FFE1E1",
  "#E1EFFF",
  "#F2F7FC",
  "#d1e7dd",
  "#33F9FF",
];
export const getRandomColor = () => {
  return colors[Math.floor(Math.random() * colors.length)];
};

export const drawGrid = (
  gridGroup: d3.Selection<SVGGElement, unknown, null, undefined>,
  svgRef: React.MutableRefObject<SVGSVGElement | null>,
) => {
  if (!svgRef.current) return;
  const width = +svgRef.current.clientWidth;
  const height = +svgRef.current.clientHeight;
  const GRID_SIZE = 20;

  for (let x = 0; x <= width; x += GRID_SIZE) {
    for (let y = 0; y <= height; y += GRID_SIZE) {
      gridGroup
        .append("circle")
        .attr("cx", x)
        .attr("cy", y)
        .attr("r", 1)
        .attr("fill", "#ccc");
    }
  }
};

export const getFloorplanLimits = (rooms: Room[]) => {
  let minFPX: number = Number.MAX_VALUE,
    maxFPX: number = Number.MIN_VALUE,
    minFPY: number = Number.MAX_VALUE,
    maxFPY: number = Number.MIN_VALUE;

  rooms.forEach((room) => {
    room?.walls?.forEach((wall) => {
      minFPX = Math.min(minFPX, wall.x1, wall.x2);
      maxFPX = Math.max(maxFPX, wall.x1, wall.x2);
      minFPY = Math.min(minFPY, wall.y1, wall.y2);
      maxFPY = Math.max(maxFPY, wall.y1, wall.y2);
    });
  });

  return { minFPX, maxFPX, minFPY, maxFPY };
};

export const getRoomLimits = (room: Room) => {
  let minX: number = Number.MAX_VALUE,
    maxX: number = Number.MIN_VALUE,
    minY: number = Number.MAX_VALUE,
    maxY: number = Number.MIN_VALUE;
    room.walls?.forEach(wall => {
      minX = Math.min(minX, wall.x1, wall.x2);
      maxX = Math.max(maxX, wall.x1, wall.x2);
      minY = Math.min(minY, wall.y1, wall.y2);
      maxY = Math.max(maxY, wall.y1, wall.y2);
    })
    return { minX, maxX, minY, maxY };
}

export const getRoomsLimits = (wall: Wall, rooms: Room[]) => {
  let minX: number = Number.MIN_VALUE,
    maxX: number = Number.MAX_VALUE,
    minY: number = Number.MIN_VALUE,
    maxY: number = Number.MAX_VALUE;
  let roomMinX: number = Number.MIN_VALUE,
    roomMaxX: number = Number.MAX_VALUE,
    roomMinY: number = Number.MIN_VALUE,
    roomMaxY: number = Number.MAX_VALUE;

  rooms.forEach((room) => {
    if (wall?.roomIds?.includes(room?.id)) {
      if (room) {
        // min/max of room
        let mnX: number = Number.MIN_VALUE,
          mxX: number = Number.MAX_VALUE,
          mnY: number = Number.MIN_VALUE,
          mxY: number = Number.MAX_VALUE;
        const isWallHorizontal: boolean = wall.y1 === wall.y2;
        room?.walls?.forEach((w) => {
          if(w !== wall) {
            const isWHorizontal: boolean = w.y1 === w.y2;
            if(isWallHorizontal && isWHorizontal) {
              const noOverlap: boolean = Math.max(wall.x1, wall.x2) <= Math.min(w.x1, w.x2) || Math.min(wall.x1, wall.x2) >= Math.max(w.x1, w.x2);
              // if walls don't overlap then they won't restrict each other
              if(!noOverlap) {
                const isUp: boolean = wall.y1 < w.y1;
                // limits defined by uppermost lower wall
                if(isUp) {
                  mxY = Math.min(mxY, w.y1);
                }
                // limits defined by lowermost upper wall
                else {
                  mnY = Math.max(mnY, w.y1);
                }
              }
            }
            else if(!isWallHorizontal && !isWHorizontal) {
              const noOverlap: boolean = Math.max(wall.y1, wall.y2) <= Math.min(w.y1, w.y2) || Math.min(wall.y1, wall.y2) >= Math.max(w.y1, w.y2);
              if(!noOverlap) {
                const isLeft: boolean = wall.x1 < w.x1;
                // // limits defined by leftmost right wall
                if(isLeft) {
                  console.log(w.id)
                  mxX = Math.min(mxX, w.x1);
                  console.log(wall.x1, w.x1, mxX)
                }
                // limits defined by rightmost left wall
                else {
                  console.log(w.id)
                  mnX = Math.max(mnX, w.x1);
                  console.log(wall.x1, w.x1, mnX);
                }
              }
            }
          }
        });
        roomMinX = Math.max(roomMinX, mnX);
        roomMaxX = Math.min(roomMaxX, mxX);
        roomMinY = Math.max(roomMinY, mnY);
        roomMaxY = Math.min(roomMaxY, mxY);
      }
    }
    minX = roomMinX === Number.MIN_VALUE ? wall.x1 : roomMinX;
    maxX = roomMaxX === Number.MAX_VALUE ? wall.x1 : roomMaxX;
    minY = roomMinY === Number.MIN_VALUE ? wall.y1 : roomMinY;
    maxY = roomMaxY === Number.MAX_VALUE ? wall.y1 : roomMaxY;
  });
  return { minX, maxX, minY, maxY };
};

export const getLimits = (wall: Wall, rooms: Room[]) => {
  let x1: number, x2: number, y1: number, y2: number;
  x1 = wall.x1;
  x2 = wall.x2;
  y1 = wall.y1;
  y2 = wall.y2;

  // min/max coords of floorplan
  let { minFPX, maxFPX, minFPY, maxFPY } = getFloorplanLimits(rooms);

  let { minX, minY, maxX, maxY } = getRoomsLimits(wall, rooms);

  rooms.forEach((room) => {
    if (!wall.roomIds.includes(room.id)) {
      room?.walls?.forEach((w) => {
        let mnX: number = minX,
          mxX: number = maxX,
          mnY: number = minY,
          mxY: number = maxY;
        if (w !== wall) {
          // for vertical wall, horizontal w
          if (x1 === x2 && w.y1 === w.y2) {
            // x1 or x2 lies on w
            if (
              (w.x1 === x1 || w.x2 === x1) &&
              Math.abs(y1 - w.y1) + Math.abs(y2 - w.y1) === Math.abs(y1 - y2)
            ) {
              let flag: boolean = false;
              // rooms.forEach(r => {
              //   if (!wall.roomIds.includes(r.id)) {
              //     r.walls?.forEach(rw => {
              //       if(rw !== wall && rw !== w && rw.y1 === rw.y2 && rw.y1 === w.y1) {
              //         if(Math.abs(y1 - rw.y1) + Math.abs(y2 - rw.y2) === Math.abs(y1 - y2)) {
              //           flag = true;
              //         }
              //       }
              //     })
              //   }
              // })
              // w is in the left
              if (w.x1 <= x1 && w.x2 <= x1 && !flag)
                mnX = Math.min(w.x1, w.x2);
              else if(!flag)
                mxX = Math.max(w.x1, w.x2);
            }
          }

          // for horizontal wall, vertical w
          if (y1 === y2 && w.x1 === w.x2) {
            // y1 or y2 lies on w
            if (
              (w.y1 === y1 || w.y2 === y1) &&
              Math.abs(x1 - w.x1) + Math.abs(x2 - w.x2) === Math.abs(x1 - x2)
            ) {
              let flag: boolean = false;
              // rooms.forEach(r => {
              //   if (!wall.roomIds.includes(r.id)) {
              //     r.walls?.forEach(rw => {
              //       if(rw !== wall && rw !== w && rw.x1 === rw.x2 && rw.x1 === w.x1) {
              //         if(Math.abs(x1 - rw.x1) + Math.abs(x2 - rw.x2) === Math.abs(x1 - x2)) {
              //           flag = true;
              //         }
              //       }
              //     })
              //   }
              // })
              // w in the upper side
              if (w.y1 <= y1 && w.y2 <= y1 && !flag)
                mnY = Math.min(w.y1, w.y2);
              else if(!flag)
                mxY = Math.max(w.y1, w.y2);
            }
          }
          minX = Math.max(mnX, minX);
          maxX = Math.min(mxX, maxX);
          minY = Math.max(mnY, minY);
          maxY = Math.min(mxY, maxY);
        }
      });
    }
  });

  // external walls - allowed to move inwards only
  if (
    (x1 === x2 && (minFPX === x1 || maxFPX === x1)) ||
    (y1 === y2 && (minFPY === y1 || maxFPY === y1))
  ) {
      // for vertical wall - check if it's leftmost or rightmost
      if(x1 === x2) {
        if(minFPX === x1 || minFPX === x2) {
          minX = minFPX;
        }
        else {
          maxX = maxFPX;
        }
      }
      // for horizontal wall - check if it's uppermost or lowermost
      else {
        if(minFPY === y1 || minFPY === y2) {
          minY = minFPY;
        }
        else {
          maxY = maxFPY;
        }
      }
  }

  return { minX, maxX, minY, maxY };
};
