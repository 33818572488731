import React from "react";
import help from "./../../../assets/inputGraphEditor/help.svg";
import { Box, Typography, Tooltip, Button } from "@mui/material";
import { useDispatch } from "react-redux";
import { resetNodesState } from "../../../redux/features/nodes";
import { resetRoomState } from "../../../redux/features/room/room.slice";

export default function BottomNav() {
  const dispatch = useDispatch();

  const handleReload = () => {
    console.log("refresh triggered");
    dispatch(resetNodesState());
    dispatch(resetRoomState());
  };

  const tooltipContent = (
    <Box
      sx={{
        padding: "8px",
        borderRadius: "4px",
        backgroundColor: "#FFFFFF",
        boxShadow: 3,
        minWidth: "250px",
      }}
    >
      <Typography
        sx={{
          color: "#111111",
          textAlign: "left",
          fontFamily: "Poppins",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: 500,
          lineHeight: "20px",
          marginBottom: ".75rem",
          whiteSpace: "nowrap",
        }}
      >
        Index
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "0.5rem",
          whiteSpace: "nowrap",
          marginBottom: "0.5rem",
        }}
      >
        <Box
          sx={{ backgroundColor: "#ED4337", height: "4px", width: "40px" }}
        ></Box>
        <Typography
          sx={{
            color: "#111111",
            textAlign: "left",
            fontFamily: "Poppins",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "20px",
            whiteSpace: "nowrap",
          }}
        >
          No common walls or Door
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "0.5rem",
          whiteSpace: "nowrap",
          marginBottom: "0.5rem",
        }}
      >
        <Box
          sx={{ backgroundColor: "#1C4C82", height: "4px", width: "40px" }}
        ></Box>
        <Typography
          sx={{
            color: "#111111",
            textAlign: "left",
            fontFamily: "Poppins",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "20px",
            whiteSpace: "nowrap",
          }}
        >
          Connect Rooms with Door
        </Typography>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", gap: "0.2rem" }}>
        <Box
          sx={{ backgroundColor: "#1C4C82", height: "4px", minWidth: "7px" }}
        ></Box>
        <Box
          sx={{ backgroundColor: "#1C4C82", height: "4px", minWidth: "7px" }}
        ></Box>
        <Box
          sx={{ backgroundColor: "#1C4C82", height: "4px", minWidth: "7px" }}
        ></Box>
        <Box
          sx={{ backgroundColor: "#1C4C82", height: "4px", minWidth: "7px" }}
        ></Box>
        <Typography
          sx={{
            color: "#111111",
            textAlign: "left",
            fontFamily: "Poppins",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "20px",
          }}
        >
          Connect Rooms with Common Wall
        </Typography>
      </Box>
    </Box>
  );

  return (
    <div
      style={{
        position: "fixed",
        zIndex: 1000,
        left: "15px",
        bottom: "15px",
        width: "70%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Tooltip title={tooltipContent} arrow placement="top">
          <div style={{ zIndex: 100, cursor: "pointer", position: "relative" }}>
            <img src={help} height="40px" width="40px" alt="Help icon" />
            <Typography
              sx={{
                pr: "15px",
                color: "#1C4C82",
                fontFamily: "Poppins",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "24px",
              }}
            >
              Help
            </Typography>
          </div>
        </Tooltip>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "1rem",
            paddingRight:"2rem",
            
          }}
        >
          <Button
            variant="outlined"
            sx={{
              borderColor: "#ED4337",
              color: "#ED4337",
              padding: "12px 20px",
              textTransform: "capitalize",
              backgroundColor: "#FDEDEC",
              fontWeight: 500,
              "&:hover": {
                borderColor: "#ED4337",
                backgroundColor: "#F9CACA",
              },
            }}
            onClick={handleReload}
          >
            Reset & Clear All Nodes
          </Button>
        
            
              </Box>
            </Box>
          
        
    </div>
  );
}
