import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

interface graphDataInterface {
  corridor: boolean;
  dimensioned: boolean;
  dimensionedCirculation: boolean;
  minDimEnabled: boolean;
  removeAddCirculation: boolean;
  publicEnabled: boolean;
  normalizeConst: boolean;
  limit: number;
  corridorThickness: number;
  starting_from: number;
  count: number;
  rectangular: boolean;
  nodes: { id: number; x: number; y: number; label: string; color: string }[];
  edges: { source: number; target: number }[];
}

export const fetchGraphData = createAsyncThunk(
  "graph/fetchGraphData",
  async ({
    graphData,
    type,
  }: {
    graphData: graphDataInterface;
    type: string;
  }) => {
    console.log("graphData before call", graphData);
    console.log(process.env);
    const response = await axios
      .post(
        `${process.env.REACT_APP_BASEURL}/api/generate/${type}`,
        graphData
      )
      .then((response) => {
        console.log("Response received:", response);
        return response;
      })
      .catch((error) => {
        console.log("Error occurred:", error);
        return error; // Re-throw the error to ensure the thunk recognizes it as a failure
      });

    // console.log(
    //   "new floorplansss",
    //   response.data.response.Documents.floorPlans,
    // );
    return response.data.response.Documents.floorPlans;
  },
);
