import {
  Box,
  Button,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

// import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
// import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import loginBG from "../../assets/loginBG.png";
// import googleLogo from "../resources/googleLogo.png";
// import fbLogo from "../resources/fbLogo.png";
import axios from "axios";
import { useDispatch } from "react-redux";
import { AppDispatch, persistor } from "../../redux/store";
import { showToast } from "../../redux/features/toast/toast.slice";
import { reset as resetGraph } from "../../redux/features/graph";
import { reset as resetNodes } from "../../redux/features/nodes";
import { reset as resetNodeEditor } from "../../redux/features/nodeEditor";
import { reset as resetApp } from "../../redux/features/appState";
import { reset as resetUser } from "../../redux/features/user/user.slice";
import { reset as resetRoom } from "../../redux/features/room/room.slice";
import { reset as resetShape } from "../../redux/features/shape/shape.slice";
interface userInterface {
  name: string;
  username: string;
  email: string;
  profession: string;
  organization: string;
  password: string;
  country: string;
}
function SignUpPage() {
  const navigate = useNavigate();
  const [userData, setUserData] = useState<userInterface>({
    name: "",
    username: "",
    email: "",
    profession: "",
    organization: "",
    password: "",
    country: "",
  });
  const [pswd, setPswd] = useState("");
  const [confirmPswd, setConfirmPswd] = useState("");
  // const [isVisible1, setvisibility1] = useState(false);
  // const [isVisible2, setvisibility2] = useState(false);

  // console.log(name);
  // console.log(pswd);
  // console.log(confirmPswd);

  const countries = [
    "Afghanistan",
    "Albania",
    "Algeria",
    "Andorra",
    "Angola",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bhutan",
    "Bolivia",
    "Bosnia and Herzegovina",
    "Botswana",
    "Brazil",
    "Brunei",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cabo Verde",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Colombia",
    "Comoros",
    "Congo",
    "Costa Rica",
    "Croatia",
    "Cuba",
    "Cyprus",
    "Czech Republic",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "East Timor",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Eswatini",
    "Ethiopia",
    "Fiji",
    "Finland",
    "France",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Greece",
    "Grenada",
    "Guatemala",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Honduras",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran",
    "Iraq",
    "Ireland",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Kuwait",
    "Kyrgyzstan",
    "Laos",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Mauritania",
    "Mauritius",
    "Mexico",
    "Micronesia",
    "Moldova",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Morocco",
    "Mozambique",
    "Myanmar",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "North Korea",
    "North Macedonia",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Poland",
    "Portugal",
    "Qatar",
    "Romania",
    "Russia",
    "Rwanda",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Vincent and the Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Korea",
    "South Sudan",
    "Spain",
    "Sri Lanka",
    "Sudan",
    "Suriname",
    "Sweden",
    "Switzerland",
    "Syria",
    "Taiwan",
    "Tajikistan",
    "Tanzania",
    "Thailand",
    "Togo",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "United States",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Vatican City",
    "Venezuela",
    "Vietnam",
    "Yemen",
    "Zambia",
    "Zimbabwe",
  ];

  const dispatch = useDispatch<AppDispatch>();

  let onSubmit = () => {
    if (
      userData.name.length === 0 ||
      userData.username.length === 0 ||
      userData.email.length === 0 ||
      userData.profession.length === 0 ||
      userData.organization.length === 0 ||
      userData.country.length === 0
    ) {
      console.log(userData);
      dispatch(
        showToast({
          message: "Please input all the fields!",
          type: "error",
        }),
      );
    } else if (pswd === confirmPswd) {
      axios
        .post(`${process.env.REACT_APP_BASEURL}/users/auth/signup/`, {
          ...userData,
          password: pswd,
        })
        .then((response) => {
          dispatch(resetGraph());
          dispatch(resetNodes());
          dispatch(resetNodeEditor());
          dispatch(resetApp());
          dispatch(resetUser());
          dispatch(resetRoom());
          dispatch(resetShape());
          navigate("/home");
        })
        .catch((error) => {
          dispatch(
            showToast({
              message: error.response.data.message,
              type: "error",
            }),
          );
        });
    } else {
      dispatch(
        showToast({
          message: "Passwords do not match!",
          type: "error",
        }),
      );
    }
  };
  // const handleChangeVisibility1 = () => {
  //     setvisibility1(!isVisible1);
  // };

  // const handleChangeVisibility2 = () => {
  //     setvisibility2(!isVisible2);
  // };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          width: "100vw",
          background: `url(${loginBG}), lightgray 50% / cover no-repeat`,
          backgroundSize: "cover",
        }}
      >
        <Box
          sx={{
            display: "inline-flex",
            padding: "12px 44px 12px 44px",
            flexDirection: "column",
            alignItems: "center",
            gap: "24px",
            borderRadius: "8px",
            background: "#FFF",
            boxShadow: "0px 4.4px 11px 0px rgba(138, 142, 148, 0.05)",
          }}
        >
          <Box sx={{ height: "91.204px", width: "105.313px" }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="96"
              height="110"
              viewBox="0 0 96 110"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M48.0009 2L93.6018 28.3283V80.9839L48.0009 107.313L2.39819 80.9839V28.3283L48.0009 2Z"
                stroke="#1C4C82"
                strokeWidth="3.59941"
                strokeMiterlimit="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M21.5623 69.9565L65.9584 96.2337"
                stroke="#1C4C82"
                strokeWidth="3.78886"
                strokeMiterlimit="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M74.6055 69.9565L29.2772 96.2336"
                stroke="#1C4C82"
                strokeWidth="3.81813"
                strokeMiterlimit="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M47.9983 2V54.6563M47.9983 54.6563L2.94873 80.8096M47.9983 54.6563L93.6012 80.6264"
                stroke="#1C4C82"
                strokeWidth="3.59941"
                strokeMiterlimit="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M21.2759 18.6323L21.3536 50.033L21.3345 69.6664"
                stroke="#1C4C82"
                strokeWidth="3.78886"
                strokeMiterlimit="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M48.2749 3.3501L74.6075 69.9564"
                stroke="#1C4C82"
                strokeWidth="3.78886"
                strokeMiterlimit="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                display: "inline",
                color: "#282828",
                fontFeatureSettings: `'clig' off, 'liga' off`,
                fontFamily: "Poppins",
                fontSize: "26.4px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "39.6px" /* 150% */,
              }}
            >
              Start Your Journey With
              <Typography
                component="span"
                sx={{
                  color: "#1C4C82",
                  fontFeatureSettings: `'clig' off, 'liga' off`,
                  fontFamily: "Poppins",
                  fontSize: "26.4px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "39.6px",
                }}
              >
                {" "}
                GPLAN
              </Typography>
            </Typography>
            <Typography
              sx={{
                color: "#949CA9",
                /* SubText */
                fontFamily: "Poppins",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "18px" /* 150% */,
              }}
            >
              Lets Design Something Great
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "12px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                width: "506px",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "2px",
              }}
            >
              <Typography
                sx={{
                  alignSelf: "stretch",
                  color: "#282828",

                  /* SubText */
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "18px",
                }}
              >
                Full Name
              </Typography>
              <TextField
                fullWidth
                size="small"
                required={true}
                InputLabelProps={{
                  shrink: false,
                  style: {
                    color: "#949CA9",
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "18px",
                  },
                }}
                placeholder="Enter your Full Name"
                id="fullWidth name"
                value={userData.name}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setUserData({ ...userData, name: event.target.value });
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                width: "506px",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "2px",
              }}
            >
              <Typography
                sx={{
                  alignSelf: "stretch",
                  color: "#282828",

                  /* SubText */
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "18px",
                }}
              >
                Username
              </Typography>
              <TextField
                fullWidth
                size="small"
                required={true}
                InputLabelProps={{
                  shrink: false,
                  style: {
                    color: "#949CA9",
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "18px",
                  },
                }}
                placeholder="Enter your Username"
                id="fullWidth username"
                value={userData.username}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setUserData({ ...userData, username: event.target.value });
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                width: "506px",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "2px",
              }}
            >
              <Typography
                sx={{
                  alignSelf: "stretch",
                  color: "#282828",

                  /* SubText */
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "18px",
                }}
              >
                Email
              </Typography>
              <TextField
                fullWidth
                size="small"
                required={true}
                InputLabelProps={{
                  shrink: false,
                  style: {
                    color: "#949CA9",
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "18px",
                  },
                }}
                placeholder="Enter your Email"
                id="fullWidth email"
                value={userData.email}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setUserData({ ...userData, email: event.target.value });
                }}
              />
            </Box>
            <Box
              sx={{ display: "flex", alignItems: "flex-start", gap: "22px" }}
            >
              <Box
                sx={{
                  display: "flex",
                  width: "242px",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  gap: "2px",
                }}
              >
                <Typography
                  sx={{
                    color: "#282828",

                    /* SubText */
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "18px",
                  }}
                >
                  Profession
                </Typography>
                <TextField
                  fullWidth
                  required={true}
                  size="small"
                  InputLabelProps={{
                    shrink: false,
                    style: {
                      color: "#949CA9",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "18px",
                    },
                  }}
                  placeholder="Enter your Profession"
                  id="fullWidth Profession"
                  value={userData.profession}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setUserData({
                      ...userData,
                      profession: event.target.value,
                    });
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  width: "242px",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  gap: "2px",
                }}
              >
                <Typography
                  sx={{
                    color: "#282828",

                    /* SubText */
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "18px",
                  }}
                >
                  Organization
                </Typography>
                <TextField
                  fullWidth
                  required={true}
                  size="small"
                  InputLabelProps={{
                    shrink: false,
                    style: {
                      color: "#949CA9",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "18px",
                    },
                  }}
                  placeholder="Enter your Organization"
                  id="fullWidth Organization"
                  value={userData.organization}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setUserData({
                      ...userData,
                      organization: event.target.value,
                    });
                  }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                width: "506px",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "2px",
              }}
            >
              <Typography
                sx={{
                  alignSelf: "stretch",
                  color: "#282828",
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "18px",
                }}
              >
                Country
              </Typography>
              <Select
                fullWidth
                size="small"
                value={userData.country}
                onChange={(event) => {
                  setUserData({ ...userData, country: event.target.value });
                }}
                displayEmpty
                sx={{
                  width: "100%",
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "18px",
                  color: "#282828",
                  ".MuiInputBase-input": {
                    padding: "8.5px 14px",
                  },
                  ".MuiOutlinedInput-notchedOutline": {
                    borderColor: "#949CA9",
                  },
                }}
              >
                <MenuItem value="">Enter your Country</MenuItem>
                {countries.map((country) => (
                  <MenuItem key={country} value={country}>
                    {country}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box
              sx={{ display: "flex", alignItems: "flex-start", gap: "22px" }}
            >
              <Box
                sx={{
                  display: "flex",
                  width: "242px",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  gap: "2px",
                }}
              >
                <Typography
                  sx={{
                    color: "#282828",

                    /* SubText */
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "18px",
                  }}
                >
                  Password
                </Typography>
                <TextField
                  fullWidth
                  required={true}
                  size="small"
                  InputLabelProps={{
                    shrink: false,
                    style: {
                      color: "#949CA9",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "18px",
                    },
                  }}
                  type="password"
                  label={pswd === "" ? `${"Enter your password"}` : ""}
                  name="password"
                  value={pswd}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setPswd(event.target.value);
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  width: "242px",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  gap: "2px",
                }}
              >
                <Typography
                  sx={{
                    color: "#282828",

                    /* SubText */
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "18px",
                  }}
                >
                  Confirm Password
                </Typography>
                <TextField
                  fullWidth
                  required={true}
                  size="small"
                  InputLabelProps={{
                    shrink: false,
                    style: {
                      color: "#949CA9",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "18px",
                    },
                  }}
                  type="password"
                  label={
                    confirmPswd === "" ? `${"Re-Enter your password"}` : ""
                  }
                  name="confirm-password"
                  value={confirmPswd}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setConfirmPswd(event.target.value);
                  }}
                />
              </Box>
            </Box>
            <Button
              fullWidth
              size="large"
              variant="contained"
              sx={{
                backgroundColor: "#1C4C82",
              }} /* sx={{textTransform: "lowercase !important"}} */
              onClick={onSubmit}
            >
              <Typography
                sx={{
                  color: "#FFF",
                  fontFamily: "Poppins",
                  fontSize: "17.6px",
                  fontStyle: "normal",
                  fontWeight: "500",
                  lineHeight: "26.4px",
                }}
              >
                Sign Up
              </Typography>
            </Button>
          </Box>
          <Typography
            sx={{
              color: "#282828",
              /* SubText */
              fontFamily: "Poppins",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 400,
              marginTop: -1,
            }}
          >
            {" "}
            Already a member?{" "}
            <Link to="/">
              <Typography
                component="span"
                sx={{
                  color: "#009EF7",

                  /* SubText */
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "18px",
                }}
              >
                Login
              </Typography>
            </Link>
          </Typography>
        </Box>
      </Box>
    </>
  );
}

export default SignUpPage;
